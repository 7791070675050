<template>
  <a-layout id="custom-layout">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div class="custom-logo">
        <img v-if="!collapsed" src="/resources/images/logo-white.svg" alt="" class="mx-auto my-4 d-block">
        <img v-if="collapsed" src="/resources/images/logo-white.svg" alt="" class="mx-auto logo-mini" style="width: 55px">
      </div>
      <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys">
        <a-menu-item v-for="page in pages" :key="page.id">
          <a-typography-link @click="router.push({ name: 'documentation', params: { page: page['slug'] === '-' ? null : page['slug'] } })">
            <i :class="'mr-3 fe fe-' + page.logo"></i><span :class="{ 'hide': collapsed }">{{ page.title }}</span>
          </a-typography-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '0 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
      >
        <template v-for="section in sections" :key="section.id">
          <a-typography-title v-if="section.type === 'title'">{{ section.content }}</a-typography-title>
          <a-typography-paragraph v-else-if="section.type === 'blockquote'" :style="{ maxWidth: section.width ? (section.width + 'px') : 'auto' }">
            <blockquote>{{ section.content }}</blockquote>
          </a-typography-paragraph>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <a-typography-paragraph v-else-if="section.type === 'paragraph'" :style="{ maxWidth: section.width ? (section.width + 'px') : 'auto' }" v-html="section.content"></a-typography-paragraph>
          <!--eslint-enable-->
          <a-typography-paragraph v-else-if="section.type === 'keyboard'" keyboard><span style="color: #595c97">{{ section.content }}</span></a-typography-paragraph>
          <a-typography-paragraph v-else-if="section.type === 'code'"><a-typography-text code><span :style="{ color: section.color ? section.color : '#595c97' }">{{ section.content }}</span></a-typography-text></a-typography-paragraph>
          <p v-else-if="section.type === 'pre'">
            <code :style="{ color: section.color ? section.color : '#595c97' }" style="white-space: pre">{{ JSON.parse(section.content) }}</code>
          </p>
          <a-typography-paragraph v-else-if="section.type === 'strong'"><a-typography-text strong>{{ section.content }}</a-typography-text></a-typography-paragraph>
          <a-typography-paragraph v-else-if="section.type === 'ol_list'" :style="{ maxWidth: section.width ? (section.width + 'px') : 'auto' }">
            <ol>
              <li v-for="(list, id) in JSON.parse(section.content)" :key="id">{{ list }}</li>
            </ol>
          </a-typography-paragraph>
          <a-typography-paragraph v-else-if="section.type === 'table'">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="section.content"></div>
            <!--eslint-enable-->
          </a-typography-paragraph>
        </template>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import {defineComponent, ref, onMounted, computed} from 'vue';
import apiClient from "@/services/axios";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  components: { MenuFoldOutlined, MenuUnfoldOutlined },
  setup() {
    const pages = ref([])
    const route = useRoute()
    const router = useRouter()
    const selectedKeys = ref([1])
    onMounted(() => {
      // if (process.env.NODE_ENV !== 'production') {
      //   router.push({ name: 'home' })
      // }
      apiClient.get(`/documentation/index`).then(({ data }) => {
        pages.value = data.data
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        if (route.params.page !== '') {
          selectedKeys.value = [pages.value.find(page => page.slug === route.params.page)['id']]
        } else {
          selectedKeys.value = [pages.value[0]['id']]
        }
      })
    })
    return {
      pages: pages,
      router: router,
      selectedKeys: selectedKeys,
      collapsed: ref(false),
      sections: computed(() => {
        return pages.value.find((item) => item['id'] === selectedKeys.value[0])?.sections
      }),
    };
  },
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
