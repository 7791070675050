<template>
  <a-layout class="vb__layout">
    <a-layout-content>
      <vb-sidebar />
      <vb-support-chat />
      <div
        :class="{
          [$style.container]: true,
          vb__layout__squaredBorders: settings.isSquaredBorders,
          vb__layout__cardsShadow: settings.isCardShadow,
          vb__layout__borderless: settings.isBorderless,
          [$style.white]: settings.authPagesColor === 'white',
          [$style.gray]: settings.authPagesColor === 'gray',
        }"
        :style="{
          backgroundImage:
            settings.authPagesColor === 'image'
              ? `url(resources/images/content/photos/8.jpeg)`
              : 'none',
        }"
      >
        <div
          :class="{
            [$style.topbar]: true,
            [$style.topbarGray]: settings.isGrayTopbar,
          }"
        >
          <div :class="$style.logoContainer">
            <div :class="$style.logo">
              <div :class="$style.name" style="width: 140px">
                <img src="/resources/images/logo-primary.svg" alt="" style="width: 120px">
              </div>
            </div>
          </div>
          <div class="d-none">
            <span class="mr-2">Don't have an account?</span>
            <router-link to="/auth/register" class="font-size-16 vb__utils__link">
              Sign up
            </router-link>
          </div>
        </div>
        <div class="mb-5 w-100">
          <router-view v-slot="{ Component }">
            <transition :name="settings.routerAnimation" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <div class="mt-auto pb-5 pt-5">
          <div class="text-center">
            Copyright © {{ new Date().getFullYear() }}
            |
            <a href="https://rhmt.uz/" target="_blank" rel="noopener noreferrer">
              О проекте
            </a>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import VbSidebar from '@/@vb/components/Sidebar'
import VbSupportChat from '@/@vb/components/SupportChat'

export default {
  name: 'AuthLayout',
  components: { VbSidebar, VbSupportChat },
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
