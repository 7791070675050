import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL + '/cabinet',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['X-Access-Token'] = `Bearer ${accessToken}`
    // request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  if ([401,403].includes(error.response.status) || error.response?.data?.error?.code === 403) {
    store.remove('accessToken')
    if (window.location.pathname !== '/auth/login') {
      notification.warning({
        message: 'Срок действия токена истек, пожалуйста авторизуйтесь заново',
      })
      setTimeout(() => {
        window.location = '/auth/login'
      }, 2000)
      return;
    }
  }
  const { response } = error
  const { data } = response

  if (data) {
    if (data.error.message) {
      notification.warning({
        message: data.error.message,
      })
    } else if (data.error.details) {
      notification.warning({
        message: data.error.details,
      })
    } else {
      data.error.forEach((error) => {
        notification.warning({
          message: error.message,
        })
      })
    }
  }
})

export default apiClient
